export const dailyTaskData = [
  {
    title: "Make Your Bed",
    isComplete: false,
    timeRemaining: "142 days",
    id: 1,
  },
  {
    title: "One hour personal coding",
    isComplete: true,
    timeRemaining: "29 days",
    id: 2,
  },
];

export const questData = [
  {
    objectiveTitle: "First Parent Objective",
    shortDescription: "Short description goes here",
    tasks: [
      {
        title: "current task goes here",
        isComplete: false,
        taskId: 3,
        timeRemaining: null,
      },
      {
        title: "Previous task would go here",
        isComplete: true,
        taskId: 2,
        timeRemaining: null,
      },
      {
        title: "This is a test task, testing for length in the DOM",
        isComplete: true,
        taskId: 1,
        timeRemaining: null,
      },
    ],
    completionScore: 72, // percent completed - make programmatically?
    questId: 2,
  },
  {
    objectiveTitle: "Second Parent Objective",
    shortDescription:
      "Short description is what is needed here, testing for length with this one :)",
    tasks: [
      {
        title: "current task goes here",
        isComplete: false,
        taskId: 3,
        timeRemaining: null,
      },
      {
        title: "Previous task would go here",
        isComplete: true,
        taskId: 2,
        timeRemaining: null,
      },
      {
        title: "This is a test task, testing for length in the DOM",
        isComplete: true,
        taskId: 1,
        timeRemaining: null,
      },
    ],
    completionScore: 45, // percent completed - make programmatically?
    questId: 1,
  },
];

export const taskDetails = [
  {
    title: "Make Your Bed",
    description:
      "You are to make your bed every day for a year. Failure to do so will cost 10 points. Completing this task daily will earn you 5 points.",
    isComplete: false,
    taskType: "Daily",
    created: new Date(2023, 3, 4),
    ending: new Date(2023, 3, 3),
    timeRemaining: "142 days",
    completionPoints: 5,
    rejectionPoints: 10,
    createdBy: "Jonathan Nammour",
    acceptedBy: ["Jonathan Nammour, Steven Elliott"],
    completedBy: [],
    id: 1,
  },
];
