import { useState } from "react";
import { TickBoxWithWords, TimeCircle } from "./SmallComponents";
import { questData } from "../testData";

const testData = questData;

export default function QuestsSection() {
  // TODO: Retrieve quests with `setQuests`
  const [quests, setQuests] = useState([]);

  if (!quests.length) {
    setQuests(testData);
  }

  return (
    <>
      <h3>Quests</h3>

      {quests.map((quest) => {
        return (
          <div className="singleQuest" key={quest.questId}>
            <div className="questTitle_row">
              <div className="questTitle_left">
                <h4>{quest.objectiveTitle}</h4>
                <i>{quest.shortDescription}</i>
              </div>

              <TimeCircle progress={quest.completionScore.toString() + "%"} />
            </div>

            <div className="tasksHolder">
              {quest.tasks.map((task) => {
                return (
                  <TickBoxWithWords
                    key={task.taskId}
                    words={task.title}
                    isChecked={task.isComplete}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}
