import { useState } from "react";

import { TickBoxWithWords, TimeCircle } from "./SmallComponents";
import { dailyTaskData } from "../testData";

const testData = dailyTaskData;

export default function DailyTasksSection() {
  // TODO: Retrieve daily Tasks with `setTasks`
  const [tasks, setTasks] = useState([]);

  if (!tasks.length) {
    setTasks(testData);
  }

  return (
    <>
      <h3>Daily Tasks</h3>

      {tasks.map((task) => {
        return (
          <div className="singleTask" key={task.id}>
            <div className="singleTask_left">
              <h4>{task.title}</h4>

              <TickBoxWithWords
                words={task.isComplete ? "Task Completed" : "Complete Task"}
                isChecked={task.isComplete}
              />
            </div>

            <TimeCircle progress={task.timeRemaining} />
            {/* <p>{JSON.stringify(task)}</p> */}
          </div>
        );
      })}
    </>
  );
}
