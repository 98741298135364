import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";

import Home from "./Pages/Home";
import ErrorPage from "./error-page";
import TaskDetails from "./Pages/TaskDetails";
import CreateTask from "./Pages/CreateTask";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,

    children: [
      // {
      //   path: "",
      //   element: null
      // },
    ],
  },
  {
    path: "/task-details",
    element: <TaskDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/create-task",
    element: <CreateTask />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
