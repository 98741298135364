export function TickBoxWithWords(props) {
  return (
    <div className="ticBoxWithWords_Outer">
      <div
        className={`ticBox ${props.isChecked ? "ticBox_checked" : ""}`}></div>
      <p>{props.words}</p>
    </div>
  );
}

export function TimeCircle(props) {
  return (
    <>
      <div className="timeCircle">
        <p>{props.progress}</p>
      </div>
    </>
  );
}
