import { useState } from "react";

export default function CreateTask() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    isComplete: false,
    taskType: "",
    created: "",
    ending: "",
    completionPoints: 0,
    rejectionPoints: 0,
    createdBy: "",
    acceptedBy: [],
  });

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "isComplete") {
      console.log(event.target);
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }

  return (
    <>
      <h1>Create a new task</h1>

      <p>{JSON.stringify(formData)}</p>

      <form onSubmit={null}>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
        />

        <label htmlFor="description">Description</label>
        <input
          type="text"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />

        
      </form>
    </>
  );
}
