import React, { useEffect, useState } from "react";
import axios from "axios";

import DailyTasksSection from "../Components/DailyTasksSection";
import QuestsSection from "../Components/QuestsSection";

import "../App.css";
import { Link } from "react-router-dom";

export default function Home() {
  const [data, setData] = useState();

  function getCookie(cookieName) {
    const cookieArr = document.cookie.split(";");

    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      if (cookieName == cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  const userCookie = getCookie("JwebNetAuthToken");
  console.log(userCookie);

  function getUserData() {
    axios
      .post("https://objectifier.stevenmelliott.com/API/user_getDetails.php", {
        credentials: "include",
        JwebNetAuthToken: userCookie,
        // "oVQkGkk2k9Fa4HtYbq82OJPKdsF1V8gJOePXkJbO1121TFnHUELMYE7y6HsH",
      })
      .then((res) => {
        console.log(res);
        const resData = checkResponseSuccess(res.data);
        setData(resData);
      });
  }

  function checkResponseSuccess(resData) {
    const success = resData.success;

    if (success === false) {
      const redirectDir = resData.redirectURL;
      return window.location.assign(redirectDir);
    } else {
      return resData;
    }
  }

  useEffect(() => getUserData(), []);

  return (
    <>
      <section className="title">
        <h1>Good evening {data ? data.firstName : ""} </h1>
      </section>

      <section className="pointsSection">
        <h2>You have</h2>

        {/* TODO Change div to circle */}
        <div className="pointsCircle timeCircle">
          <p>109</p>
        </div>

        <h2>points</h2>
      </section>

      <section>ACTION NEEDED SECTION</section>

      <section id="dailyTasks_section">
        <DailyTasksSection />
      </section>

      <section id="quests_section">
        <QuestsSection />
      </section>

      <section id="temporaryLinks">
        <Link to="/task-details">Task Details</Link>
      </section>
    </>
  );
}
