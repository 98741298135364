import React, { useState } from "react";
import { taskDetails } from "../testData";
import { TickBoxWithWords } from "../Components/SmallComponents";
import { Link } from "react-router-dom";

const testData = taskDetails;

export default function TaskDetails() {
  // TODO Retrieve task details and set with setTaskDetails
  const [taskData, setTaskData] = useState([]);

  if (!taskData.length) {
    setTaskData(testData);
  }

  return (
    <>
      <h1>Task Details</h1>

      {taskData.map((task) => {
        return <SingleDetailedTask task={task} key={task.id} />;
      })}
    </>
  );
}

function SingleDetailedTask(props) {
  const task = props.task;

  return (
    <>
      <section id="taskDetails">
        <h2>{task.title}</h2>

        <div className="date_splitBox">
          <div className="splitBox splitBox_left">
            <p>Began</p>
            <p>{task.created.toDateString()}</p>
          </div>
          <div className="splitBox splitBox_right">
            <p>Finish</p>
            <p>{task.ending.toDateString()}</p>
          </div>
        </div>

        <strong className="timeRemaining">{task.timeRemaining} remaining</strong>

        <p>{task.description}</p>

        <TickBoxWithWords
          words={task.isComplete ? "Task Completed" : "Task is not complete"}
          isChecked={task.isComplete}
        />

        <div className="actionButtons_outer">
          <div className="upperBtns">
            <button>Complete</button>
          </div>
          <div className="lowerBtns">
            <button>Purpose Change</button>
            <button>Remove</button>
          </div>
        </div>
      </section>

      <hr />

      <section id="metadata">
        <h3>Proposed by</h3>
        <p>{task.createdBy}</p>

        <h3>Accepted by</h3>
        {task.acceptedBy.map((person) => {
          return <p key={person}>{person}</p>;
        })}

        <h3>Completed by</h3>
        {task.completedBy.length
          ? task.completedBy.map((person) => {
              return <p key={person}>{person}</p>;
            })
          : "None"}

        <h3>Task type</h3>
        <p>{task.taskType}</p>

        <h3>Completion Points</h3>
        <p>{task.completionPoints}</p>

        <h3>Failure Points</h3>
        <p>{task.rejectionPoints}</p>
      </section>

      <footer className="homeFooter">
        <Link to={"/"}>
          <button type="button" className="homeBtn">Home</button>
        </Link>
      </footer>
    </>
  );
}
